<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <h1>Change Password</h1>
    <p>Set your standard business hours. These times will be used for your appointment calendar.</p>
    <hr>
    <b-field class="modal-label" label="Existing password">
      <b-input v-model="passwordUpdate.currentPassword" type="password" placeholder="Enter your existing password."/>
    </b-field>
    <hr>
    <b-field class="modal-label" label="New Password">
      <b-input v-model="passwordUpdate.newPassword" type="password" placeholder="Enter a new password."/>
    </b-field>
    <b-field class="modal-label" label="Confirm Password">
      <b-input v-model="passwordUpdate.newPasswordConfirm" type="password" placeholder="Confirm your new password."/>
    </b-field>
    <hr>
    <div class="level is-mobile">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <a class="button is-light" @click="cancel">Cancel</a>
        </div>
        <div class="level-item">
          <button class="button is-primary" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'ChangePassword',
  components: { Breadcrumbs },
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Change Password', route: null }
      ],
      passwordUpdate: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
      }
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'settings-home' })
    },
    save () {
      this.$store.dispatch('settings/savePassword', this.passwordUpdate)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Password updated.',
            type: 'is-success'
          })
          this.$router.push({ name: 'settings-home' })
        })
        .catch(err => {
          const errors = err.response.data.errors
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              this.$buefy.toast.open({
                message: errors[errorKey][0],
                type: 'is-danger',
                duration: 4000,
                queue: false
              })
            })
          } else {
            this.$buefy.toast.open({
              message: `Error: ${err.message}`,
              type: 'is-danger',
              duration: 4000
            })
          }
        })
    }
  }
}
</script>
